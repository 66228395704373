import React, { Component } from 'react';
import './App.css';
import UserInfo from './UserInfo';
import DrawerMenuButton from './DrawerMenuButton';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

export default class Drawer1 extends Component {

  // Properties used by this component:
  // title

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elDrawerMenuButton2 = (ev) => {
    window.open('documents/Catalogo_de_API_Rest_OMA_V15.pdf', '_blank');
  
  }
  
  
  onClick_elDrawerMenuButton = (ev) => {
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    this.props.appActions.goToScreen('landingpage');
  
    this.props.appActions.updateDataSlot('ds_Slot_User_ID', "");
    this.props.appActions.updateDataSlot('ds_Slot_User_ID', "");
    this.props.appActions.updateDataSlot('ds_Slot_User_Email', "");
    this.props.appActions.updateDataSlot('ds_Slot_User_Name', "");
    this.props.appActions.updateDataSlot('ds_Slot_User_Photo', "");
    this.props.appActions.updateDataSlot('ds_Slot_User_Name', "");
    this.props.appActions.updateDataSlot('ds_Slot_User_Email', "");
    this.props.appActions.updateDataSlot('ds_Slot_User_Photo', "");
  
    // Go to screen 'Landing Page'
    this.props.appActions.goToScreen('landingpage', { transitionId: 'fadeIn' });
  
  }
  
  
  clearSavedLogin() {
    // Logout
    firebase.auth().signOut();
  }
  
  
  render() {
    const style_elDrawerMenuButton2 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRectangle = {
      background: 'rgba(35, 111, 234, 1.000)',
     };
    const style_elDrawerMenuButton = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Drawer1 appBg">
        <div className="layoutFlow">
          <div className="hasNestedComps elUserInfo">
            <div>
              <UserInfo ref={(el)=> this._elUserInfo = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elDrawerMenuButton2">
            <div style={style_elDrawerMenuButton2} onClick={this.onClick_elDrawerMenuButton2} >
              <DrawerMenuButton title={"Descargar Documentación"} ref={(el)=> this._elDrawerMenuButton2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="hasNestedComps elDrawerMenuButton">
            <div style={style_elDrawerMenuButton} onClick={this.onClick_elDrawerMenuButton} >
              <DrawerMenuButton title={"Salir"} ref={(el)=> this._elDrawerMenuButton = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
