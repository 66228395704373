import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_569434";
    item['en'] = "Logout";
    item['es'] = "Salir";
    
    item = {};
    this.items.push(item);
    item['key'] = "sandbox_button2_185494";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "sandbox_text_65781";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "sandbox_button2_171761";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_button_440941";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_button2_308502";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_button3_639818";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_button4_888198";
    item['en'] = "Logout";
    item['es'] = "Salir";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_button_761250";
    item['en'] = "Menu button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawermenubutton_button_803719";
    item['en'] = "Salir";
    
    item = {};
    this.items.push(item);
    item['key'] = "userinfo_text_1038348";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "userinfo_text2_545395";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy9_523955";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy8_234905";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy7_642503";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy6_700771";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy5_645868";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy4_621628";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy3_475528";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy2_535915";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy_633825";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_button_718950";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy9_937844";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy8_601294";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy7_403617";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy6_645640";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy5_205799";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy4_65101";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy3_702180";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy2_598593";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy_706507";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_button_44571";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy9_1014264";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy8_912987";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy7_264131";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy6_462611";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy5_310868";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy4_40750";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy3_971774";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy2_842443";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy_594765";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_button_304675";
    item['en'] = "New button";
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
