import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/UserInfo_elImage_420631.jpg';
import img_elImage2 from './images/UserInfo_elImage2_766798.jpg';

export default class UserInfo extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elImage2 = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_Slot_User_Photo'] : '') || img_elImage2)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '100.0px',
     };
    let transformPropValue_text2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      if (input == '') input='Usuario Anónimo';
      return input;
    }
    
    const value_text2 = transformPropValue_text2((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_Slot_User_Name'] : ''));
    
    const style_elText2 = {
      color: 'white',
      textAlign: 'center',
     };
    let transformPropValue_text = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      
      return input;
    }
    
    const value_text = transformPropValue_text((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_Slot_User_Email'] : ''));
    
    const style_elText = {
      color: 'white',
      textAlign: 'center',
     };
    
    return (
      <div className="UserInfo">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="containerMinHeight elImage" style={style_elImage} />
        </div>
        
        <div className="layoutFlow">
          <div className="elImage2">
            <div style={style_elImage2} />
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{value_text2 !== undefined ? value_text2 : (<span className="propValueMissing">{this.props.locStrings.userinfo_text2_545395}</span>)}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.userinfo_text_1038348}</span>)}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
