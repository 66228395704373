import React, { Component } from 'react';
import './App.css';
import img_elBackground_lanscape from './images/SandboxScreen_elBackground_lanscape_324473.jpg';
import img_elImage from './images/SandboxScreen_elImage_159390.jpg';
import btn_icon_171761 from './images/btn_icon_171761.png';
import Dimmer from './Dimmer';
import Drawer1 from './Drawer1';

// UI framework component imports

export default class SandboxScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, title, drawer_is_visible, ds_dimmer_is_visible

  constructor(props) {
    super(props);
    
    this.state = {
      webcontent: 'https://apibank.openpass.com.ar/web',
      text: (<div>username</div>),
      text_plainText: "username",
      drawer1_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elButton2 = (ev) => {
    this.toggle_drawer1();
  
    let newVal = this.props.ds_dimmer_is_visible;
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.
      if (input=='false')input='true'
      else input = 'false'
      return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('(null)', newVal);
  
  }
  
  
  toggle_drawer1 = () => {
    this.setState({drawer1_isOpen: !this.state.drawer1_isOpen});
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elWebcontent = {
      pointerEvents: 'auto',
     };
    const style_elBackground_lanscape = {
      backgroundImage: 'url('+img_elBackground_lanscape+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_text = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_Slot_User_Name'] : '');
    
    const style_elText = {
      color: '#f1fff8',
      textAlign: 'right',
     };
    const style_elImage = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_Slot_User_Photo'] : '') || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '33.0px',
     };
    
    const style_elButton2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_171761+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformVisiblePropValue_dimmer = (input) => {
      // This function modifies the value for property 'visible'.
      // There is a variable named 'input' that provides the property value.
      //
      
      let isOpen=this.state.drawer1_isOpen;
      if (isOpen){
      return 'true';
      }
      return 'false';
    }
    const elDimmer = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_dimmer(this.props.drawer_is_visible)) ? (
      <div className="hasNestedComps containerMinHeight elDimmer">
        <Dimmer ref={(el)=> this._elDimmer = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    const style_drawer1 = {
      pointerEvents: (this.state.drawer1_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="AppScreen SandboxScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <iframe className="elWebcontent" style={style_elWebcontent} src={this.state.webcontent}  />
            <div className="elBackground_lanscape" style={style_elBackground_lanscape} />
            <div className="baseFont elText" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.state.text}</span>)}</div>
            </div>
            <div className="elImage" style={style_elImage} />
            <button className="actionFont elButton2" style={style_elButton2} onClick={this.onClick_elButton2}  />
            { elDimmer }
          </div>
        </div>
        <div className="drawer drawer1" style={style_drawer1} onClick={this.toggle_drawer1}>
          <div className={"drawerContent" + (this.state.drawer1_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <Drawer1 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_drawer1} />
          </div>
        </div>
      </div>
    )
  }
  
}
