import React, { Component } from 'react';
import './App.css';

export default class DrawerMenuButton extends Component {

  // Properties used by this component:
  // title

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const textValue_button = this.props.title;
    
    const style_elButton = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#0093d5',
      textAlign: 'left',
      backgroundColor: '#feffff',
     };
    
    return (
      <div className="DrawerMenuButton">
        <div className="layoutFlow">
          <div className="elButton">
            <button className="actionFont" style={style_elButton}>
              {textValue_button}
            </button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
